/* Custom CSS for hover effects, animations, and transitions */

/* Styling for navigation buttons and premium button */
.nav-button,
#go_premium_button {
  /* Basic button styles */
  background-color: transparent;
  color: white;
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 9999px;
  border: 1px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
}

/* Hover effect on navigation buttons */
.nav-button:hover,
.web-nav-button:hover {
  color: #f88742;
}

/* Animation effect when a navigation button is clicked */
.nav-button:active,
.web-nav-button:active {
  animation: pulse 0.3s; /* Trigger the 'pulse' animation */
}

/* Website Navigation */
.web-nav-button {
  background-color: transparent;
  color: #000;
  font-size: 17px;
  padding: 8px 12px;
  border-radius: 9999px;
  border: 1px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
}

.web-nav-button-signup {
  background-color: transparent;
  color: #000;
  font-size: 18px;

  border: 2px solid black;
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
}

.web-nav-button-signup:hover {
  color: white;
  background: #f88742;
  border-radius: 0;
  border: 2px solid #f88742;
}

/* Add this to your Common.css or another appropriate stylesheet */
.dropdown-item {
  display: block;
  width: 200px;
  padding: 8px 12px;
  color: #333;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease-in-out;
}

.dropdown-item:hover {
  color: #f88742;
}

/* Keyframes for the 'pulse' animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Keyframes for the 'fadeIn' animation */
@keyframes fadeIn {
  from {
    opacity: 0; /* Fully transparent */
  }
  to {
    opacity: 1; /* Fully opaque */
  }
}

/* Keyframes for the 'fadeOut' animation */
@keyframes fadeOut {
  from {
    opacity: 1; /* Fully opaque */
  }
  to {
    opacity: 0; /* Fully transparent */
  }
}

/* Sign up Page */
.signup-with-facebook {
  margin: auto;
}

/* Styling for dropdown and select-dropdown elements */
.dropdown,
.select-dropdown {
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.dropdown {
  animation-name: fadeIn;
}

.signup-error-message {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
}

/* Title input style */
.title-input-container {
  position: relative;
  width: 100%;
}

.title-input {
  /* width: 100%; */

  overflow: hidden;
  text-overflow: ellipsis;
}

/* Domain Style */
/* Adjust the submenu class */

/* You can then add CSS for your dropdown and submenu, something like: */
.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  padding: 4px 0px;
}

.subDropdown-menu {
  padding: 6px;
}

.dropdown-item-domain {
  position: relative;
  display: block;
  width: 18vw;
  padding: 5px 15px;
  color: #333;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-domain:hover {
  color: #f88742;
  background-color: #f0f0f0;
}

.submenu {
  position: absolute;
  top: -150%; /* Position the top of the submenu just below the dropdown item */
  /* bottom: 30%; */
  left: -100%;
  /* right: 30%; */
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  white-space: nowrap;
  padding: 6px;

  /* Make sure the width is set or auto to fit content */
}

.submenu-item {
  /* width: 18vw; */
  padding: 5px;
  color: black;
  cursor: pointer;
}

.submenu-item:hover {
  color: #f88742;
}

.submenu-item:hover {
  background-color: #f0f0f0;
}

.other-button {
  display: flex;
  place-content: space-between;
}

/* Express mode */
.required_symbol {
  color: #cc0000;
  font-size: 26px;
  z-index: 100;
  top: -12px;
  left: 0px;
}

.pink-text {
  color: #f4833d;
}

.gray-text {
  color: #cccccc;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider.on {
  background-color: #f4833d;
  color: white;
  padding: 2px 10px;
}

.slider.off {
  padding: 2px 30px;
  color: white;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider:before {
  transform: translateX(30px);
}

/* Example CSS in a separate CSS file or style block */
.input-box::placeholder {
  font-size: 28px; /* Set the desired font size for the placeholder text */
  /* color: #f0e1e1; */
  text-align: center;
}

.input-text-color {
  color: black;

  font-size: 20px;
}

/* Youtube Modal Style */
/* Modal Animation */
.modal-enter {
  opacity: 0;
  transform: scale(0.9);
}
.modal-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}
.modal-exit {
  opacity: 1;
  transform: scale(1);
}
.modal-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

/* Generate Questions Modal Styling */
.generate-questions-modal {
  display: flex;
  justify-content: center;
  place-content: center;
  flex-direction: column;
  width: min-content;
}

/* Styling for the stepper container */
.stepper-container {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

/* Styling for the stepper */
.stepper {
  display: flex;
  flex-direction: column;
}

/* Styling for each step in the stepper */
.step {
  display: flex;
  flex-direction: column;
  color: #93a4b3;
  margin-bottom: 20px;
}

/* Styling for the step circle (commented out, might be used elsewhere) */
.step-circle {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;

  background-color: #d5d5d8;
}

/* Styling for the active step */
.step.active .step-circle {
  border-color: gray;
  background-color: #72d1d0;
  color: #042739;
}

.step.active .step-label {
  color: white;
}

/* Styling for the completed step */
.step.complete .step-circle {
  border: 1px solid #ccc;
  background-color: rgb(213, 213, 216);
  /* color: white; */
}

.step.complete .step-label {
  color: #93a4b3;
}

.question-answer-container strong span.rounded-full {
  width: 24px; /* Adjust the width as needed */
  height: 24px; /* Set the same value as width to create a perfect circle */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Styling for the step label */
.step-label {
  font-size: 15px;
  color: #93a4b3;
}

/* Styling for the button container in the stepper */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Styling for general buttons */
.btn {
  padding: 10px 20px;
  margin: 5px 0;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Styling for disabled buttons */
.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Styling for custom scroll bar */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(197, 195, 195);
  border-radius: 9px; /* This value makes the scrollbar thumb rounded */
}

/* Styling for Modal */
.MuiStepper-root {
  width: 45%;
}

/* Styling for active step in the stepper */
.Mui-active {
  color: #f4833d !important;
}

/* Styling for completed step in the stepper */
.Mui-completed {
  color: green !important;
}

/* Question Page */
/* Styling for fadeInAnimation */
/* Keyframes for the 'fadeIn' animation */
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* CSS for the edit and delete icons */
.edit-delete-icons {
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.question-answer-container:hover .edit-delete-icons {
  display: flex;
  opacity: 1;
}

/* Review Content Page CSS */
/* .content-container {
  line-height: 1.7; 
}  */
.percent-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  padding: 0;
  width: 56px;
  line-height: 50px;
  border: 2px solid white;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  float: left;
  /* margin: -5px 20px 0 0; */
  font-weight: 500;
}

.content-readiness-meter-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
}

/* Advanced animation */
.cross-icon,
.edit-delete-icons button {
  transform: scale(0.8); /* Initial scaling */
  transition: transform 0.2s ease-in-out;
}

.cross-icon,
.edit-delete-icons button:hover {
  transform: scale(1); /* Scaling on hover */
}

/* Smoother hover effect for the entire container */
.question-answer-container {
  transition: opacity 0.2s ease-in-out;
}

.question-answer-container:hover {
  opacity: 0.95; /* Adjust the opacity value as per your preference */
}

/* Loading Overlay */
.loader {
  color: white;
  font-size: 2px;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: fixed;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.beta-ribbon {
  position: absolute;
  padding: 3px 6px;
  background: #00aced;
  box-shadow: -1px 1px 2px rgba(86, 200, 243, 0.68);
  top: 14%;
  color: #ffffff;
  left: 71%;
  font-size: 10px;
  transform: rotate(-35deg);
  z-index: 100;
}

.beta-ribbon:before {
  width: 7px;
  height: 120%;
  top: 0;
  left: -4.5px;
  padding: 0 0 4px;
  background: inherit;
  border-radius: 4px 0 0 4px;
}
.beta-ribbon:before,
.beta-ribbon:after {
  content: "";
  position: absolute;
}

/* Review Content Style */
.submenu p {
  font-size: 12px;
  color: #ffffff;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
}

.subtStep {
  border: 1px solid #d5d5d8;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding: 5px 6px;
  /* margin-right: 13px; */
  display: inline-block;
  text-align: center;
}

.complex {
  /* background-color: #feefb8; */
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  padding: 1px 3px;
  color: #777e8d;
  border: 2px solid #feefb8;
}

.curated-sentences {
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
}
.subjective {
  /* background-color: #a4fcf9; */
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  padding: 1px 3px;
  color: #777e8d;
  border: 2px solid #a4fcf9;
}

.pronoun {
  /* background-color: #fbcfc2; */
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  padding: 1px 3px;
  color: #777e8d;
  border: 2px solid #fbcfc2;
}

.incomplete {
  /* background-color: #fcd4f9; */
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  padding: 1px 3px;
  color: #777e8d;
  border: 2px solid #fcd4f9;
}

.help {
  padding: 2px 3px;
  color: white;
}

.subtStep1 {
  background-color: transparent;
  padding: 1px 5px;
  color: #777e8d;
}

/* Sign in Page */
.copyright {
  width: 30px;
  background: #eee;
}

.copyright-text {
  transform-origin: left top 0;
  transform: rotate(-90deg);
  position: absolute;
  bottom: 0;
  width: 360px;
  font-size: 11px;
  color: #777e8d;
  padding-top: 8px;
  padding-left: 34px;
}

.loader3 {
  font-size: 5px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: fixed;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em red,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em red, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em red,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em red,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em red,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em red,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em red;
  }
}

/* Assuming "dropdown-container" is the class of the outer div */
.dropdown-container .rs-dropdown-menu {
  right: 100%;
  left: auto;
  transform: translateX(-17rem);
}

/* Add this to your CSS to style the dropdown */
.pronoun-dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
}

.pronoun-dropdown:hover .dropdown-content {
  display: block;
}

.pink-text {
  color: pink;
}

/* Review Content */

.pronoun-dropdown-items {
  margin: -10px;
}

.white-space-pre-line {
  white-space: pre-line;
}

/* Style for the circle icon */
.circle-icon {
  width: 25px;
  height: 25px;
  background-color: #042739;
  border-radius: 25px;
  border: 1px solid white;
  /* Make it circle */
  position: relative;
  color: white;
  display: flex;

  justify-content: center;
  align-items: center;
}

/* Style for the count number */
.count {
  font-size: 14px;
  padding-right: 1px;
}

/* Go Unlimited Modal Style */
.modal-content {
  transform-style: preserve-3d;
  perspective: 1000px;
  position: relative;
  /* Adding these properties to ensure that the animation smoothly flips */
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

/* Defining the keyframes for the swipe-in animation */
@keyframes swipeIn {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(180deg);
  }
}

/* Defining the keyframes for the swipe-out animation */
@keyframes swipeOut {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0deg);
  }
}

/* Applying the animation when the modal is flipped */
.flipped {
  animation-name: swipeIn;
  animation-duration: 0.4s;
  /* You can adjust the duration as per your preference */
}

/* Applying the animation when the modal is unflipped */
.unflipped {
  animation-name: swipeOut;
  animation-duration: 0.4s;
  /* You can adjust the duration as per your preference */
}

.front-content {
  backface-visibility: hidden;
  /* position: absolute; */
  width: 45vw;
  /* height: 40vh; */
}

/* This is for Content Readiness Modal */
.readiness-pass-front-content {
  backface-visibility: hidden;
  /* position: absolute; */
  width: 60vw;
  /* height: 40vh; */
}
.back-content {
  backface-visibility: hidden;
  /* position: absolute; */
  width: 40vw;
  height: 100%;
}

.back-content {
  transform: rotateY(180deg);
}

.upgradeToProBackContent {
  backface-visibility: hidden;
  /* position: absolute; */
  width: max-content;
  height: 100%;
  transform: rotateY(180deg);
}

@keyframes wave {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

/* MUI Tooltip Styles */
/* The above code is defining CSS styles for tooltips. */
/* .MuiTooltip-tooltip {
  background-color: rgb(230, 211, 253) !important;
  color: #fd5959 !important;
} */
/* 
.MuiTooltip-arrow {
  color: rgb(247, 215, 220) !important;
} */

.qti-image {
  filter: brightness(115%) saturate(100%) hue-rotate(37deg);
}

/* History Page CSS */
.double-underline {
  text-decoration: underline;
  text-decoration-style: double;
}

/* Keywords Page Styling */
.keyword {
  /* other styles */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: grab;
}

.keyword.dragging {
  cursor: grabbing;
  /* opacity: 0.7; */
  border: 1px solid #f8711d;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: all 0.4 ease-in-out;
}

.keyword.dropZone {
  /* border: 2px dashed #888888; */
  transform: scale(1.05);

  transition: all 0.3s ease;
}

.full-size-textarea {
  width: 100%;
  height: 100%;
  resize: none;
  overflow: auto;
}
